import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import {Thumbnail} from "../About/styles";
import developerImg from "../../images/developer.svg";
import skillTechnical from "../../images/skill1.svg";

const Skills = () => {
  const { projects } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Skills" />
              <Row key={0}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={500}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{"Technical skills"}</h3>
                      <div>
                        <p>Being involved in all stages of product lifecycle (from brainstorming to design and then implementation) and working closely with management and tech team.</p>
                        <p>Experience with dealing performance problems and architectural challenges with a basic understanding of fundamental design principles of a scalable application.</p>
                        <p>Main tools of trade:
                          <li>PHP/Symfony</li>
                          <li>MySQL/Redis</li>
                          <li>React/Redux/Webpack</li>
                          <li>LAMP/Docker/Vagrant/AWS</li>
                        </p>
                        <p className="mb-4">{"" || ''}</p>
                      </div>
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1000}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      <Thumbnail>
                        <img src={developerImg} alt={"Technical skills"} />
                      </Thumbnail>
                    </div>
                  </Fade>
                </Col>
              </Row>
              <Row key={1}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={500}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{"Soft skills"}</h3>
                      <div>
                        <p>High energy and enthusiastic individual, with acknowledgement toward agile team philosophy.</p>
                        <p>Able to quickly adapt to changing conditions.</p>
                        <p>Proactive, responsible and diligent.</p>
                        <p>Ensure quality and enforce best practices by communicating and sharing with other team members.</p>
                        <p className="mb-4">{"" || ''}</p>
                      </div>
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1000}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      <Thumbnail>
                        <img src={skillTechnical} alt={""} />
                      </Thumbnail>
                    </div>
                  </Fade>
                </Col>
              </Row>
        </div>
      </Container>
    </section>
  );
};

export default Skills;
